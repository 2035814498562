import React from "react"
import './scholarship.css'
import mohammad from './mohammad.jpg';
import scholarship from './1.png';
import logo from './2.png';
const Page = ({ data, location }) => {
  return <div class="scholarship-page">
    <div class="page-wrapper">
      <div className="scholarship-image-wrapper">
        <img class="scholarship" src={scholarship} alt="Scholarship" />
        <div class="scholarship-logo">
          <img src={logo} alt="Scholarship logo" width="200" />
        </div>
      </div>

      <div class="mohammad-wrapper">
        <div class="about-mohammad">
          <h1>About<br></br>Mohammad</h1>
          <p>Mohammad Arabiyat was a kind, supportive, and beautiful soul who touched the lives of everyone around him, especially those who worked with him at seagulls. Arabiyat joined Seagulls in 2019 and quickly became a valuable member of the team. He was always willing to help, and his positive attitude was contagious. He was also a great listener and always had a kind word to say. Arabiyat will be remembered for his sense of humor, and his dedication to his work.</p>
          <a className="scholarship-button" href="https://forms.gle/BidnrcmzY7txFbHj6" target="_blank">Apply now</a>
        </div>
        <img src={mohammad} alt="Mohammad" />
      </div>

    </div>
  </div>
}

export default Page
