import React, { useLayoutEffect } from "react"
import Scholarship from "../components/Scholarship"
import SEO from "../components/seo"


const Page = ({ data, location }) => {
  useLayoutEffect(() => {
    document.body.classList.add('scholarship-page');
    return () => {
      document.body.classList.remove('scholarship-page');
    }
  }, [])
  return <>
    <SEO title="Scholarship" />
    <Scholarship/>
  </>
}

export default Page
